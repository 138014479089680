import "mapbox-gl/dist/mapbox-gl.css";
import type { MapProps } from "./Map.types";
import { Map as ReactMapGL } from "react-map-gl";
import { MAPBOX_ACCESS_TOKEN } from "./Map.utils";

export const Map = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onLoad,
  interactiveLayerIds,
  style,
  token,
  mapRef,
  initialViewState,
  viewState,
}: MapProps) => (
  <ReactMapGL
    {...viewState}
    mapboxAccessToken={token || MAPBOX_ACCESS_TOKEN}
    mapStyle={style}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onLoad={onLoad}
    doubleClickZoom
    interactiveLayerIds={interactiveLayerIds}
    dragRotate={false}
    ref={mapRef}
    style={{ width: "100%", height: "100%" }}
    initialViewState={initialViewState as any}
  >
    {children}
  </ReactMapGL>
);
