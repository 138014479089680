import type { ActionBarRightProps } from "./ActionBarRight.types";
import { BodyText, Icon, Subheading } from "@urbint/silica";
import classNames from "classnames";
import { useState } from "react";
import { Button } from "@/common/Button";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import {
  useCheckDateRangeWarning,
  useDamagePreventionAuth,
  useResponsive,
} from "@/hooks";
import { Switch } from "@/components/Switch";
import { pluralize } from "@/utils";
import { useFlexTicketsCount } from "@/api";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { Dialog } from "@/components/Dialog";
import { Tooltip } from "@/common/Tooltip";
import { ActionBarRightMenu } from "./ActionBarRightMenu";

const ActionBarRight = ({
  setThresholdToastShown,
  hasSelections,
  handleClearSelection,
  isShowingTasks,
  toggleIsShowingTasks,
  bulkDisabled,
  setModal,
  triggerAction,
  selectionCount,
  allSelectedTasks,
  allTicketIds,
  ticketCount,
  showFilterModal,
}: ActionBarRightProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const { isSm } = useResponsive();
  const { selectMode, reset } = useTicketSelector();
  const { dirtyView, isInitialized } = useTicketViewEditor();
  const { isInDateRangeWithinWarningLimit, limitDays } =
    useCheckDateRangeWarning();
  const { isFetching: ticketsLoading } = useFlexTicketsCount(dirtyView, {
    enabled: isInitialized,
  });

  const handleExitSelectMode = () => {
    setThresholdToastShown(false);
    reset();
  };

  const {
    currentUser: { canAccessFiltersAndSorting },
  } = useDamagePreventionAuth();

  const warningTitle = `Your current view is now limited to the last ${limitDays} days`;
  const warningBodyText = "Please update your date selection to view data.";

  return (
    <div className="flex justify-between flex-shrink w-full h-12 min-w-0 px-3 py-1 sm:justify-end sm:flex-1 sm:py-0 sm:h-full sm:w-auto sm:px-0">
      {!selectMode && (
        <Button
          variant="tertiary"
          className={classNames("sm:ml-2", { "!px-0": !isSm })}
          onClick={() => showFilterModal(true)}
          disabled={!canAccessFiltersAndSorting}
          data-testid="filter-modal-button"
        >
          <div className="flex items-center space-x-1">
            <Icon name="filter" className="text-xl" />
            <p>{!ticketsLoading && pluralize(ticketCount, "ticket")}</p>
          </div>
        </Button>
      )}
      {dirtyView.filters && isInDateRangeWithinWarningLimit(dirtyView.filters) && (
        <>
          <button
            className={classNames(
              "flex items-center p-2 hover:bg-neutral-shade-background-light"
            )}
            onClick={() => setShowWarning(true)}
            type="button"
          >
            <Tooltip
              title={`${warningTitle}. ${warningBodyText}`}
              disabled={!isSm}
            >
              <Icon
                name="warning"
                className="text-xl text-feedback-warning-content"
              />
            </Tooltip>
          </button>
          <Dialog
            variant="warning"
            isOpen={showWarning}
            onClose={() => setShowWarning(false)}
            header=""
          >
            <Subheading>{warningTitle}</Subheading>
            <BodyText className="pt-2">{warningBodyText}</BodyText>
          </Dialog>
        </>
      )}

      <div className={classNames("flex-1 sm:flex", { hidden: selectMode })} />
      {isSm && selectMode !== "tasks" && (
        <div className="flex items-center h-full border-r border-solid border-neutral-shade-border-default sm:px-2">
          <Switch
            label="Show tasks"
            checked={isShowingTasks}
            onChange={() => toggleIsShowingTasks()}
            dataTestId="show-task-switch"
          />
        </div>
      )}
      {/* Overflow menu */}
      <div
        className={classNames(
          "h-full flex items-center justify-between sm:justify-end flex-shrink min-w-0 sm:px-2",
          { "flex-1": selectMode && !isSm }
        )}
      >
        {selectMode && (
          <div
            className="flex items-center flex-1 h-full px-0 font-normal sm:px-2 whitespace-nowrap"
            data-testid="items-selected"
          >
            {pluralize(
              selectionCount,
              selectMode === "tickets" ? "ticket" : "task"
            )}{" "}
            selected{isSm && ":"}
          </div>
        )}
        <ActionBarRightMenu
          hasSelections={hasSelections}
          bulkDisabled={bulkDisabled}
          triggerAction={triggerAction}
          setModal={setModal}
          allTicketIds={allTicketIds}
          allSelectedTasks={allSelectedTasks}
        />
      </div>
      <div>
        {selectMode && isSm && (
          <div className="flex items-center flex-none h-full px-2 border-l border-solid border-neutral-shade-border-default">
            {hasSelections && (
              <Button
                className="text-base font-semibold text-neutral-shade-secondary"
                variant="tertiary"
                onClick={handleClearSelection}
                data-testid="clear-selection-option"
              >
                Clear selection
              </Button>
            )}
            <div
              className="px-1 cursor-pointer"
              onClick={handleExitSelectMode}
              data-testid="close-selection-mode-button"
            >
              <Icon className="text-xl" name="close_big" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ActionBarRight };
