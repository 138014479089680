import type { UseQueryOptions } from "@tanstack/react-query";
import type { TicketVersionSerializer } from "@/utils/damage-prevention";
import type { UseQueryReturnOptions } from "@/api";
import { useQuery } from "@tanstack/react-query";

import { TicketVersion } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const ticketVersionsIdent = (ticketId: number) => ["ticket_versions", ticketId];

const fetchTicketVersions = (ticketId: number) =>
  api
    .getPage<TicketVersionSerializer>({
      endPoint: "ticket_versions",
      page: 1,
      queryParams: {
        ticket: ticketId.toString(),
      },
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new TicketVersion(r)));

const useTicketVersions = (
  ticketId: number,
  {
    sorter,
    ...opts
  }: UseQueryOptions<TicketVersion[]> &
    UseQueryReturnOptions<TicketVersion> = {}
) =>
  useQuery<TicketVersion[]>(
    ticketVersionsIdent(ticketId),
    async () => {
      const results = await fetchTicketVersions(ticketId);

      if (sorter) {
        results.sort(sorter);
      }

      return results;
    },
    { ...opts }
  );

export { useTicketVersions, ticketVersionsIdent };
