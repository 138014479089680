import type { ClusterMarkerCardProps } from "./ClusterMarkerCard.types";
import { Icon } from "@urbint/silica";
import { useMap } from "react-map-gl";
import { parseRisks } from "@/components/Map/Popups";
import { pluralize, handleMapZoom } from "@/utils";
import { RiskChip } from "@/components/Map/RiskChip";
import { Button } from "@/common/Button";
import { isImpactPotentialKey } from "./ClusterMarkerCard.utils";

const ClusterMarkerCard = ({
  onClick,
  selectedCount,
  risks,
  className,
  popupCoordinates,
}: ClusterMarkerCardProps) => {
  const { current: map } = useMap();
  const totalCount =
    Object.values(parseRisks(risks)).reduce((a, b) => a + b, 0) ?? 0;

  const onClickHandler = () => {
    if (map && popupCoordinates) {
      handleMapZoom(map, popupCoordinates);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={`font-sans w-full ${className}`}>
      <Button
        className="bg-white w-full drop-shadow-md rounded-lg p-4"
        onClick={onClickHandler}
      >
        {selectedCount > 0 && (
          <div className="flex space-x-1 items-center font-sans border-b border-neutral-shade-border-default border-solid pb-3 mb-2">
            <Icon className="text-brand-40 mr-1 mt-0.5" name="circle_check" />
            <span className="font-semibold text-base">
              {pluralize(selectedCount, "ticket")}
            </span>
            <span>{` selected`}</span>
          </div>
        )}
        <div>
          <div className="font-semibold text-neutral-shade-secondary mb-2 text-sm">
            Cluster has {pluralize(totalCount, "ticket")}
          </div>
          <div className="flex space-x-1">
            {risks &&
              Object.keys(risks)
                .filter(isImpactPotentialKey)
                .map(
                  (r) =>
                    risks[r] > 0 && (
                      <RiskChip key={r} type={r} count={risks[r]} />
                    )
                )}
          </div>
        </div>
      </Button>
    </div>
  );
};

export { ClusterMarkerCard };
