import type { FieldAvailableFilterDefinition } from "@/api/tickets";
import type { SelectOption } from "@/components/Select";
import type { FiltersType } from "./FilterDialogFieldFilterCard.types";
import { useMemo } from "react";
import { type DateRangeFilter, type SavedViewFilter } from "@/models/SavedView";
import {
  DropdownMultiSelect,
  DropdownSelect,
  TypeaheadSelect,
} from "@/components/Select";
import { FilterType, useTicketFilterOptions } from "@/api";
import { Spinner } from "@/common/Spinner";
import { DateTimeInput } from "@/components/DateTimeInput";
import { useDamagePreventionAuth } from "@/hooks";
import { RangeSlider } from "@/components/RangeSlider/RangeSlider";

export const buildTicketFilter = (
  { filter, excludeFilter }: FiltersType,
  definition: FieldAvailableFilterDefinition,
  formatCasing: boolean,
  onChange: (value: SavedViewFilter, excludeValue?: SavedViewFilter) => void
) => {
  const { currentUser } = useDamagePreventionAuth();
  const { data: initialFieldOptions, isInitialLoading } =
    useTicketFilterOptions(definition.field, formatCasing, {
      enabled: definition.type !== FilterType.Date,
    });

  const filterFieldOptions = (
    fieldOptions: SelectOption<any>[] | undefined
  ) => {
    if (
      ["task_assignees", "open_task_assignees"].includes(definition.field) &&
      currentUser.canOnlyViewTicketsWithOwnedTasks
    ) {
      return fieldOptions?.filter((option) => option.value === "me");
    }
    return fieldOptions;
  };

  const fieldOptions = filterFieldOptions(initialFieldOptions);

  return useMemo(() => {
    if (isInitialLoading) {
      return (
        <div className="flex items-center justify-center my-8">
          <Spinner />
          <p className="ml-2 text-neutral-shade-tertiary">Loading ...</p>
        </div>
      );
    }

    if (definition.type === FilterType.String) {
      return (
        <input
          type="text"
          className="flex items-center w-full px-3 leading-tight text-left text-black truncate border rounded h-9 text-opacity-88"
          value={(filter.value as string | undefined) || ""}
          onChange={(e) => onChange({ ...filter, value: e.target.value })}
        />
      );
    }
    if (
      definition.type === FilterType.Typeahead ||
      definition.type === FilterType.SelectOne
    ) {
      return (
        <DropdownSelect
          className="border"
          options={fieldOptions}
          value={filter.value as any}
          onChange={(value) => onChange({ ...filter, value: value as any })}
        />
      );
    }
    if (definition.type === FilterType.TypeaheadMultiple) {
      return (
        <TypeaheadSelect
          name={definition.field}
          includeOptions={fieldOptions}
          excludeOptions={fieldOptions}
          includeValue={filter.value as any}
          excludeValue={excludeFilter?.value as any}
          dataTestId={filter.field}
          onChange={(include, exclude) => {
            if (exclude) {
              return onChange(
                { ...filter, value: include as any },
                { ...filter, value: exclude as any }
              );
            }

            return onChange({ ...filter, value: include as any });
          }}
        />
      );
    }
    if (definition.type === FilterType.SelectMultiple) {
      return (
        <DropdownMultiSelect
          className="border rounded"
          options={fieldOptions}
          value={filter.value as any}
          onChange={(value) => onChange({ ...filter, value: value as any })}
          dataTestId={filter.field}
        />
      );
    }
    if (definition.type === FilterType.Date) {
      const { before, after } = (filter?.value as DateRangeFilter) ?? {};

      return (
        <DateTimeInput
          staticMode
          startDate={after}
          endDate={before}
          mode="range"
          type="datetime"
          relative
          onDatesChange={([afterDate, beforeDate]: (
            | Date
            | undefined
            | string
          )[]) => {
            onChange({
              ...filter,
              value: {
                type: "date",
                after:
                  afterDate instanceof Date
                    ? afterDate.toISOString()
                    : afterDate,
                before:
                  beforeDate instanceof Date
                    ? beforeDate.toISOString()
                    : beforeDate,
              },
            });
          }}
        />
      );
    }

    if (definition.type === FilterType.RangeSlider) {
      // Not pretty but i needed to enforce types here (same has the other types)
      const rangeValues = filter.value
        ? String(filter.value)
            .split(",")
            .map(Number)
            .filter((v) => !Number.isNaN(v))
        : [];

      return (
        <RangeSlider
          min={rangeValues[0]}
          max={rangeValues[1]}
          onValuesChange={(value) =>
            onChange({ ...filter, value: `${value[0]},${value[1]}` })
          }
        />
      );
    }

    return <p>Component not yet implemented: {JSON.stringify(definition)}</p>;
  }, [definition, filter, fieldOptions, isInitialLoading, onChange]);
};
