import type { FeatureCollection } from "geojson";
import type { UseQueryOptions } from "@tanstack/react-query";
import type { RequestDataProps } from "./useFlexTickets";
import type { prepareTicketParamsProps } from "@/api";
import { useQuery } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import {
  getDaysUntilDateOrDefault,
  prepareRequestBody,
  usePublicConfig,
} from "@/api";
import { useDebounce } from "@/hooks";
import { queryClient } from "@/api/client";

type Point = { type: "Point"; coordinates: [number, number] };
type TicketCentroids = FeatureCollection<
  Point,
  {
    /** Abbreviated on the server to reduce payload */
    // Damage Potential
    d?: string;
    // Impact Potential
    i?: string;
    // Due Date
    t?: string;
    /** Computed client side */
    risk: string;
    selected?: boolean;
  }
>;

const api = new UrbintApi();

const ticketPointsIdentKey = "ticket-points";

const ticketPointsIdent = (opts: RequestDataProps) => [
  ticketPointsIdentKey,
  JSON.stringify(prepareRequestBody(opts)),
];

const fetchTicketPoints = (opts: RequestDataProps) => {
  // Cancel inflight requests
  api.abort();

  return api
    .post("ticket_points", JSON.stringify(prepareRequestBody(opts)))
    .then(handleApiResponse);
};

const useTicketPoints = (
  args: RequestDataProps,
  opts?: UseQueryOptions<TicketCentroids>
) => {
  const { data: publicConfig, isFetched } = usePublicConfig();
  const searchTimeLimitInDays = getDaysUntilDateOrDefault(
    publicConfig?.savedViewSearchLimit
  );
  const requestData: prepareTicketParamsProps = {
    ...args,
    searchTerm: useDebounce(args?.searchTerm, { delay: 400 }),
    searchTimeLimitInDays,
  };
  const ident = ticketPointsIdent(requestData);
  return useQuery<TicketCentroids>(
    ident,
    () => fetchTicketPoints(requestData),
    { ...opts, enabled: opts?.enabled && isFetched }
  );
};

const invalidateUseTicketPointsQuery = () => {
  queryClient.invalidateQueries([ticketPointsIdentKey]);
};

export type { Point, TicketCentroids };
export {
  ticketPointsIdentKey,
  ticketPointsIdent,
  useTicketPoints,
  invalidateUseTicketPointsQuery,
};
