import type { RequestDataProps, UseFlexTicketsOpts } from "@/api/tickets";
import type { prepareTicketParamsProps } from "@/api/utils";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { useDebounce } from "@/hooks";
import { getDaysUntilDateOrDefault, prepareRequestBody } from "@/api/utils";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { queryClient } from "@/api/client";
import { usePublicConfig } from "@/api/publicConfig";

const api = new UrbintApi();

interface FlexTicketsCount {
  count?: number;
}

const flexTicketsCountIdentKey = ["flex_tickets_count"];
const flexTicketsCountIdent = (requestData: string) => [
  flexTicketsCountIdentKey,
  requestData,
];
const fetchTicketsCount = (requestData: string) => async () => {
  // Cancel previous requests
  api.abort();
  const res = await api.post("tickets_flex/count", requestData);

  return handleApiResponse(res);
};
const useFlexTicketsCount = (
  args: UseFlexTicketsOpts,
  opts?: UseQueryOptions<FlexTicketsCount>
) => {
  const { data: publicConfig, isFetched } = usePublicConfig();
  const searchTimeLimitInDays = getDaysUntilDateOrDefault(
    publicConfig?.savedViewSearchLimit
  );

  const requestData: prepareTicketParamsProps = {
    ...args,
    searchTerm: useDebounce(args.searchTerm, { delay: 400, throttle: false }),
    searchTimeLimitInDays,
  };

  const requestBody = JSON.stringify(prepareRequestBody(requestData));

  return useQuery<FlexTicketsCount>(
    flexTicketsCountIdent(requestBody),
    fetchTicketsCount(requestBody),
    { ...opts, enabled: opts?.enabled && isFetched }
  );
};

const prefetchFlexTicketsCount = (
  args: RequestDataProps,
  opts?: UseQueryOptions<FlexTicketsCount>
) => {
  const { data: publicConfig } = usePublicConfig();
  const searchTimeLimitInDays = getDaysUntilDateOrDefault(
    publicConfig?.savedViewSearchLimit
  );
  const requestBody = JSON.stringify(
    prepareRequestBody({ ...args, searchTimeLimitInDays })
  );
  queryClient.prefetchQuery(
    flexTicketsCountIdent(requestBody),
    fetchTicketsCount(requestBody),
    opts
  );
};

export {
  useFlexTicketsCount,
  prefetchFlexTicketsCount,
  flexTicketsCountIdentKey,
};
