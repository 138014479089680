import type { Position } from "geojson";
import type { MapRef } from "react-map-gl";

const MAX_SAFE_ZOOM = 20;

/**
 * Adjusts the zoom level of the map to a new position.
 *
 * @param currentMap - The map reference to apply the zoom change.
 * @param coordinates - The longitude and latitude to center the map on.
 * @param increment - The amount to increase the current zoom level by. Defaults to 1.
 * @param maxZoom - The maximum zoom level to allow. Defaults to 20.
 */
const handleMapZoom = (
  currentMap: MapRef,
  coordinates: Position,
  increment = 1,
  maxZoom = MAX_SAFE_ZOOM
) => {
  const [longitude, latitude] = coordinates;
  if (longitude && latitude) {
    const currentZoom = currentMap.getZoom();
    const newZoomLevel = Math.min(currentZoom + increment, maxZoom);
    currentMap.easeTo({
      center: [longitude, latitude],
      zoom: newZoomLevel,
      essential: true,
    });
  }
};

export { handleMapZoom };
