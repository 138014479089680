import type { SavedViewFilter } from "@/models";
import {
  getDaysUntilDateOrDefault,
  isDateRangeFilter,
  isDateRangeWithinLimit,
  usePublicConfig,
} from "@/api";

/**
 * Check if date range filter is within limit according to the feature flag from public config.
 * For more details check: https://urbint.atlassian.net/browse/DPAPP-2697
 *
 */
const useCheckDateRangeWarning = () => {
  const { data: publicConfig } = usePublicConfig();

  // Get the limit for the date range from the public config
  const limitDays = getDaysUntilDateOrDefault(
    publicConfig?.savedViewSearchLimit
  );

  const isInDateRangeWithinWarningLimit = (filters: SavedViewFilter[]) => {
    // Check if at least one filter has an invalid date range
    const hasInvalidDateRange = filters.some(
      (filter) =>
        isDateRangeFilter(filter.value) &&
        isDateRangeWithinLimit(filter.value, limitDays) // Check for invalid date range
    );

    return hasInvalidDateRange;
  };

  return { isInDateRangeWithinWarningLimit, limitDays: Math.abs(limitDays) };
};

export { useCheckDateRangeWarning };
