import type { GeoAssetsLayerProps } from "./GeoAssetsLayer.types";
import { useEffect } from "react";
import { Layer, useMap, Source } from "react-map-gl";

import { usePublicConfig } from "@/api";
import {
  ANNOTATIONS_ELEC_DIST_LAYER_PROPS,
  ANNOTATIONS_NETWORK_LAYER_PROPS,
  DAMAGES_LAYER_PROPS,
  ELECTRIC_DISTRIBUTION_LINE_LAYER_PROPS,
  ELECTRIC_DISTRIBUTION_STRUCTURE_LAYER_PROPS,
  ELECTRIC_TRANSMISSION_LINE_LAYER_PROPS,
  ELECTRIC_TRANSMISSION_STRUCTURE_LAYER_PROPS,
  FIBER_LINE_LAYER_PROPS,
  FIBER_POLYGON_LAYER_PROPS,
  FIBER_POLYGON_OUTLINE_LAYER_PROPS,
  FIBER_STRUCTURE_LAYER_PROPS,
  MAINS_LAYER_PROPS,
  NETWORK_LINE_LAYER_PROPS,
  NETWORK_STRUCTURE_LAYER_PROPS,
  REGULATOR_STATIONS_LAYER_PROPS,
  SERVICES_LAYER_PROPS,
  TELCO_LINE_LAYER_PROPS,
  TELCO_POLYGON_LAYER_PROPS,
  TELCO_POLYGON_OUTLINE_LAYER_PROPS,
  TRANSMISSIONS_LAYER_PROPS,
  VALVES_LAYER_PROPS,
} from "./GeoAssetsLayer.constants";
import { GEO_ASSETS_IMAGES } from "./GeoAssetsLayer.utils";

const PORT_STR = window.location.port ? `:${window.location.port}` : "";
const GEO_ASSET_URL = `${window.location.protocol}//${window.location.hostname}${PORT_STR}/api/geo_assets/tiles/{z}/{x}/{y}/`;

const GeoAssetsLayer = ({
  utilityShown,
  electricDistributionShown,
  electricTransmissionShown,
  fiberShown,
  networkShown,
  telcoShown,
  buffersShown,
  damagesShown,
  annotationsShown,
}: GeoAssetsLayerProps) => {
  const { current: map } = useMap();
  const { data: publicConfig } = usePublicConfig();

  if (!map) return null;

  const layerGroups = [
    /*
      Note: More details about zoom flow on Layer+Source creation below

      visibleZoom:
        The zoom level the layers on this group should show
      lowestZoomToLoad:
        The last loaded source tiles from BE
        Note, layers keep showing data from visibleZoom up to zoom 24
    */
    {
      name: "utility",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible: utilityShown && publicConfig?.mapLayer.transmissionLines,
          props: TRANSMISSIONS_LAYER_PROPS,
        },
        {
          visible: utilityShown && publicConfig?.mapLayer.mains,
          props: MAINS_LAYER_PROPS,
        },
        {
          visible: utilityShown && publicConfig?.mapLayer.regulatorStations,
          props: REGULATOR_STATIONS_LAYER_PROPS,
        },
      ],
    },
    {
      name: "utilityLines",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible: utilityShown && publicConfig?.mapLayer.serviceLines,
          props: SERVICES_LAYER_PROPS,
        },
        {
          visible: utilityShown && publicConfig?.mapLayer.valves,
          props: VALVES_LAYER_PROPS,
        },
      ],
    },
    {
      name: "electricDistribution",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible:
            electricDistributionShown &&
            publicConfig?.mapLayer.electricDistribution,
          props: ELECTRIC_DISTRIBUTION_LINE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "electricDistributionStructure",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible:
            electricDistributionShown &&
            publicConfig?.mapLayer.electricDistribution,
          props: ELECTRIC_DISTRIBUTION_STRUCTURE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "electricTransmission",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible:
            electricTransmissionShown &&
            publicConfig?.mapLayer.electricTransmission,
          props: ELECTRIC_TRANSMISSION_LINE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "electricTransmissionStructure",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible:
            electricTransmissionShown &&
            publicConfig?.mapLayer.electricTransmission,
          props: ELECTRIC_TRANSMISSION_STRUCTURE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "fiber",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible: fiberShown && publicConfig?.mapLayer.fiber,
          props: FIBER_POLYGON_LAYER_PROPS,
        },
        {
          visible: fiberShown && publicConfig?.mapLayer.fiber,
          props: FIBER_POLYGON_OUTLINE_LAYER_PROPS,
        },
        {
          visible: fiberShown && publicConfig?.mapLayer.fiber,
          props: FIBER_LINE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "fiberStructure",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible: fiberShown && publicConfig?.mapLayer.fiber,
          props: FIBER_STRUCTURE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "network",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible: networkShown && publicConfig?.mapLayer.network,
          props: NETWORK_LINE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "networkStructure",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible: networkShown && publicConfig?.mapLayer.network,
          props: NETWORK_STRUCTURE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "buffers",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible: buffersShown && publicConfig?.mapLayer.buffers,
          props: TELCO_POLYGON_LAYER_PROPS,
        },
        {
          visible: buffersShown && publicConfig?.mapLayer.buffers,
          props: TELCO_POLYGON_OUTLINE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "telco",
      visibleZoom: 12,
      lowestZoomToLoad: 16,
      layers: [
        {
          visible: telcoShown && publicConfig?.mapLayer.telco,
          props: TELCO_LINE_LAYER_PROPS,
        },
      ],
    },
    {
      name: "damages",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible: damagesShown && publicConfig?.mapLayer.damages,
          props: DAMAGES_LAYER_PROPS,
        },
      ],
    },
    {
      name: "annotations",
      visibleZoom: 15,
      lowestZoomToLoad: 15,
      layers: [
        {
          visible:
            annotationsShown &&
            electricDistributionShown &&
            publicConfig?.mapLayer.annotations,
          props: ANNOTATIONS_ELEC_DIST_LAYER_PROPS,
        },
        {
          visible:
            annotationsShown &&
            networkShown &&
            publicConfig?.mapLayer.annotations,
          props: ANNOTATIONS_NETWORK_LAYER_PROPS,
        },
      ],
    },
  ];

  useEffect(() => {
    map.on("load", () => {
      GEO_ASSETS_IMAGES.map((image) =>
        map.loadImage(
          image,
          (error?: Error, data?: HTMLImageElement | ImageBitmap) => {
            if (error) throw error;
            if (!data) return;
            map.addImage(image, data);
          }
        )
      );
    });
  }, [map]);

  const sources = [];
  const layers = [];
  for (const layerGroup of layerGroups) {
    const layerTiles = [];
    const groupId = `geo-${layerGroup.name}`;
    for (const layer of layerGroup.layers) {
      layerTiles.push(layer.props["source-layer"]);
      if (layer.visible) {
        layers.push(
          <Layer
            key={layer.props.id}
            source={groupId}
            minzoom={layerGroup.visibleZoom}
            {...layer.props}
          />
        );
      }
    }

    /*
      Because this layers share the same data between zooms (no data is omitted in higher zooms),
        we only load from the BE visibleZoom and lowestZoomToLoad zoom levels data
      Meaning:
        - if visibleZoom=12 and lowestZoomToLoad=16 and:
          - if active zoom is from 0 to 11, no data is loaded
          - if active zoom is from 12 to 15, only one call is made to the BE to load zoom=12
          - if active zoom is >= 16, only one call is made to the BE to load zoom=16
        - if visibleZoom=15 and lowestZoomToLoad=15 and:
          - if active zoom is from 0 to 14, no data is loaded
          - if active zoom is >= 15, only one call is made to the BE to load zoom=15

      Why use this flow?
        The main reason is that urbint tiles have the same data across zooms, meaning zoom=0 will have all tiles data of all zoom=24 tiles
        resulting in a massive tile size for smaller zooms
      What this hack tries to solve?
        Reuse as much as possible the zoom tiles dataand reduce the number of calls to the BE
      The expected improvements:
        - Reduce the number of call to the BE to fetch the same data, this should give some breath to the BE and speed up things for GPRS users
        - We reduce the number of cached tiles on BE side, should give some space to redis/cache
        - Because the BE uses Cache-control header, it should allow the browser to use cached tiles on next visit
    */
    const minzoom = layerGroup.visibleZoom;
    // Prefix with 9900 so we know we can hack this source
    const maxzoom = 9900 + layerGroup.lowestZoomToLoad;

    /*
      Layers are seperated into grouped Sources to parallelize requests and reduce tiles size.
      This way we don't reload all layers when only one group is activated and we save some requests to the BE
        (the browser have a concurrent connection limit (6) to the same BE host)
      And, because layers are grouped by same zoom levels, we don't load tiles for layers that don't need it.
        For example, layer1 with visibleZoom=12 and layer2 with visibleZoom=14, we don't need to load
        tiles for zoom=12,13 on layer2 with this group by zoom levels flow.
    */
    sources.push(
      <Source
        id={groupId}
        key={groupId}
        type="vector"
        minzoom={minzoom}
        maxzoom={maxzoom}
        tiles={[`${GEO_ASSET_URL}?layers=${layerTiles.join()}`]}
      />
    );
  }

  return (
    <>
      {sources}
      {layers}
    </>
  );
};

export { GeoAssetsLayer };
