import type { TooltipProps } from "./Tooltip.types";
import { useCallback, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import cx from "classnames";
import { Portal } from "@/components/Portal";

const Tooltip = ({
  title,
  children,
  disabled,
  origin = "top",
  theme = {},
  testId,
}: TooltipProps) => {
  const [isOpen, setOpen] = useState(false);
  const containerRef = useRef<HTMLSpanElement | null>(null);
  const tipRef = useRef<HTMLDivElement | null>(null);
  const isTooltipEnabled = title && !disabled;
  const { tooltip = "", container = "" } = theme;

  let originClass;
  switch (origin) {
    case "top":
      originClass = "-translate-x-1/2 origin-top mt-2";
      break;
    case "left":
      originClass = "-translate-y-1/2 origin-left ml-2";
      break;
    case "right":
      originClass = "-translate-y-1/2 origin-right mr-2";
      break;
    // no default
  }

  const tooltipClassname = `${originClass} bg-black bg-opacity-88 shadow-xl px-2 py-1 text-white text-sm rounded-md ${tooltip}`;

  const beforeEnter = useCallback(() => {
    const rect = containerRef.current!.getBoundingClientRect();
    const tip = tipRef.current!;
    if (!tip || !rect) return;
    const safetyMargin = 10;
    switch (origin) {
      case "top":
        tip.style.top = `${rect.bottom}px`;
        // eslint-disable-next-line no-case-declarations
        const leftPosition = rect.left + rect.width / 2 - safetyMargin;
        // eslint-disable-next-line no-case-declarations
        const diff = leftPosition - tip.clientWidth / 2;
        if (diff < 0) {
          // tooltip is cut on the left side
          tip.style.left = `${leftPosition - diff + safetyMargin}px`;
        } else {
          tip.style.left = `${leftPosition}px`;
        }
        break;
      case "left":
        tip.style.left = `${rect.left + rect.width}px`;
        tip.style.top = `${rect.top + rect.height / 2}px`;
        break;
      case "right":
        tip.style.right = `${window.innerWidth - rect.left}px`;
        tip.style.top = `${rect.top + rect.height / 2}px`;
        break;
      // no default
    }
  }, [origin]);

  return (
    <>
      <span
        className={cx("inline-flex items-center", container)}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        ref={containerRef}
      >
        <span className="pointer-events-none inline-flex items-center w-full">
          {children}
        </span>
      </span>
      {isTooltipEnabled && (
        <Portal>
          <div ref={tipRef} className="absolute z-[10000]" data-testid={testId}>
            <Transition
              as="div"
              className={tooltipClassname}
              show={isOpen}
              appear
              beforeEnter={beforeEnter}
              enter="transition ease-out duration-150"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              {title}
            </Transition>
          </div>
        </Portal>
      )}
    </>
  );
};

export { Tooltip };
