import type { FlexTask, FlexTicket } from "@/models";
import type { VirtualListViewDelegateProps } from "../VirtualList/VirtualList.types";
import { useCallback, useMemo, useRef } from "react";

import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { flexFieldsForTicketCard } from "@/components/TicketCard";
import { useFlexTickets, useFlexTicketsCount } from "@/api";
import { useTicketFocusContext } from "@/pages/MapPage";
import { useTicketViewEditor } from "../TicketViewEditorProvider";
import { VirtualList } from "../VirtualList";
import { TicketListItem } from "./TicketListItem";
import { TicketListCardPlaceholder } from "./TicketListCardPlaceholder";

const DEFAULT_PLACEHOLDER_CARDS = 20;

const TicketList = () => {
  const { dirtyView, isInitialized, isShowingTasks, showTask } =
    useTicketViewEditor();
  const fields = useMemo(
    () => flexFieldsForTicketCard(isShowingTasks),
    [isShowingTasks]
  );
  const { data, fetchMissingItems, isFetched } = useFlexTickets(
    { ...dirtyView, fields },
    { enabled: isInitialized }
  );

  const {
    toggleTicketId,
    toggleTask,
    selectedTickets,
    getSelectedTasksForTicket,
    selectMode,
  } = useTicketSelector();

  const { setFocusedTicket } = useTicketFocusContext();

  const parentRef = useRef<HTMLDivElement>(null);

  const tickets = useMemo(
    () => (data?.pages ? data?.pages.flatMap((p) => p.data) : []),
    [data?.pages]
  );

  const handleSelectTicket = useCallback(
    (ticket: FlexTicket) => {
      toggleTicketId(ticket.id!);
    },
    [toggleTicketId]
  );

  const handleSelectTask = useCallback(
    (task: FlexTask, ticketId: number) => {
      toggleTask(task, ticketId);
    },
    [toggleTask]
  );

  const renderTicketItem = useCallback(
    ({ index, item }: VirtualListViewDelegateProps<FlexTicket>) => {
      const ticket = item;
      const selectedTasksForTicket = getSelectedTasksForTicket(ticket);
      const isSelected = selectedTickets.includes(ticket.id!);
      return (
        <TicketListItem
          key={index}
          ticket={ticket}
          isSelected={isSelected}
          onSelect={handleSelectTicket}
          onSelectTask={handleSelectTask}
          selectMode={selectMode}
          isShowingTasks={isShowingTasks}
          selectedTasks={selectedTasksForTicket}
          shouldShowTask={showTask}
          onHover={setFocusedTicket}
        />
      );
    },
    // Make sure to include everything that should trigger a re-render here

    [
      selectedTickets,
      getSelectedTasksForTicket,
      selectMode,
      isShowingTasks,
      handleSelectTask,
      handleSelectTicket,
      showTask,
      setFocusedTicket,
    ]
  );

  const keyExtractor = useCallback(
    (index: number) => `${dirtyView.name}-${index}`,
    [dirtyView?.name]
  );

  return (
    <div
      data-testid="search-tickets-list"
      className={`w-full sm:w-[theme(spacing.sidebar)] absolute h-full right-0 top-0 ${
        isFetched ? "overflow-auto" : "overflow-hidden"
      }`}
    >
      {isFetched && tickets?.length === 0 && (
        <p
          className="text-black text-opacity-66 w-full text-center mt-6"
          data-testid="search-tickets-no-match"
        >
          No tickets match the current filters.
        </p>
      )}
      {!isFetched &&
        [...Array(DEFAULT_PLACEHOLDER_CARDS).keys()].map((key) => (
          <TicketListCardPlaceholder key={key} />
        ))}
      <div ref={parentRef} className="h-full relative overflow-scroll w-full">
        <VirtualList
          estimateItemHeight={isShowingTasks ? 113 : 187}
          keyExtractor={keyExtractor}
          items={tickets}
          needsMoreItemsThreshold={5}
          onNeedsMoreItems={fetchMissingItems}
          scrollParentRef={parentRef}
          ViewDelegate={renderTicketItem}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { TicketList };
