import type { currentUserDecoratorParams } from "@urbint/auth-client";
import type {
  DamagePreventionUserProfile,
  DjangoRegistrationResult,
} from "./types";
import {
  canAccessAdminPanel,
  canAccessBatchingFunctions,
  canAccessFiltersAndSorting,
  canAccessReportsSql,
  canAddTaskComment,
  canAddTicketComment,
  canModifyTickets,
  canAssignTasks,
  canBeAssignedToInspectorTasks,
  canBeAssignedToLocatorTasks,
  canCreateSavedView,
  canCreateTasks,
  canDeletePhotos,
  canDismissNotification,
  canModifyTicketQueues,
  canMergeTicketQueues,
  canRemoveTicketQueueItems,
  canForwardTickets,
  canEmailTickets,
  canEditDeleteAllTasks,
  canEditDeleteMyTasks,
  canEditReportsChartsAndDashboards,
  canEditResponseForm,
  canEditTaskStatus,
  canImportReportData,
  canMarkNotificationRead,
  canShareSavedView,
  canSubmitResponseForm,
  canUploadPhotos,
  canViewReports,
  canAccessSettings,
  canCreateNonTicketedEvents,
  canBeAssignedToNonTicketedEvents,
  canEditSubmittedNonTicketedEventsResponseForm,
  canOnlyViewTicketsWithOwnedTasks,
  canEditTicketLifeCycle,
  canViewModelExplainer,
  canAssignEvents,
  canAccessNonTicketsEvents,
  canAccessTickets,
} from "./djangoPermissions";
import { getServerUrl } from "./getServerUrl";
import { initPendo } from "./initPendo";

/**
 * Attempts to produce a meaningful error message for the user.
 */
async function handleDjangoErrorResponse(
  response: Response
): Promise<Error | DjangoRegistrationResult> {
  let json: DjangoRegistrationResult;
  try {
    json = await response.json();

    if (response.ok) return json;
    if ((json as any).message) return new Error((json as any).message);
    return new Error(`Server responded with ${response.status}`);
  } catch (e) {
    return new Error(`Server responded with ${response.status}`);
  }
}

/**
 * Sends the user's bearer token to the server to register the new session. The
 * server will use that token to create or update a copy of that user record.
 * The response from the server is expected to be decorated with information
 * about the user, including the server's id for the record as well as
 * permissions applicable to the user.
 */
const decorateCurrentUser = async ({
  profile,
  ctx,
}: currentUserDecoratorParams): Promise<DamagePreventionUserProfile> => {
  try {
    const { token } = JSON.parse(localStorage.getItem("tkn") || "{}");
    const requestToken = token || ctx.bearerToken;
    const displayName = `${profile.firstName} ${profile.lastName}`.trim();
    const registerUrl = getServerUrl({ pathname: "/register/" });
    const response = await fetch(registerUrl.href, {
      method: "get",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${requestToken}`,
        "Content-Type": "application/json",
      },
    });

    const errorOrResult = await handleDjangoErrorResponse(response);
    if (errorOrResult instanceof Error) throw errorOrResult;

    const { user } = errorOrResult;
    const permObj = { permissions: user.user_permissions };

    const decoratedUser = {
      ...profile,
      displayName: displayName.length > 0 ? displayName : profile.username,
      djangoId: parseInt(`${user.id ?? "0"}`, 10),
      canAccessAdminPanel: canAccessAdminPanel(permObj),
      canAccessBatchingFunctions: canAccessBatchingFunctions(permObj),
      canAccessFiltersAndSorting: canAccessFiltersAndSorting(permObj),
      canAccessReportsSql: canAccessReportsSql(permObj),
      canAddTaskComment: canAddTaskComment(permObj),
      canAddTicketComment: canAddTicketComment(permObj),
      canModifyTickets: canModifyTickets(permObj),
      canAssignTasks: canAssignTasks(permObj),
      canBeAssignedToInspectorTasks: canBeAssignedToInspectorTasks(permObj),
      canBeAssignedToLocatorTasks: canBeAssignedToLocatorTasks(permObj),
      canCreateSavedView: canCreateSavedView(permObj),
      canCreateTasks: canCreateTasks(permObj),
      canDeletePhotos: canDeletePhotos(permObj),
      canDismissNotification: canDismissNotification(permObj),
      canModifyTicketQueues: canModifyTicketQueues(permObj),
      canMergeTicketQueues: canMergeTicketQueues(permObj),
      canRemoveTicketQueueItems: canRemoveTicketQueueItems(permObj),
      canForwardTickets: canForwardTickets(permObj),
      canEmailTickets: canEmailTickets(permObj),
      canEditDeleteAllTasks: canEditDeleteAllTasks(permObj),
      canEditDeleteMyTasks: canEditDeleteMyTasks(permObj),
      canEditReportsChartsAndDashboards:
        canEditReportsChartsAndDashboards(permObj),
      canEditResponseForm: canEditResponseForm(permObj),
      canEditTaskStatus: canEditTaskStatus(permObj),
      canImportReportData: canImportReportData(permObj),
      canMarkNotificationRead: canMarkNotificationRead(permObj),
      canShareSavedView: canShareSavedView(permObj),
      canSubmitResponseForm: canSubmitResponseForm(permObj),
      canUploadPhotos: canUploadPhotos(permObj),
      canViewReports: canViewReports(permObj),
      canAccessSettings: canAccessSettings(permObj),
      canCreateNonTicketedEvents: canCreateNonTicketedEvents(permObj),
      canOnlyViewTicketsWithOwnedTasks:
        canOnlyViewTicketsWithOwnedTasks(permObj),
      canBeAssignedToNonTicketedEvents:
        canBeAssignedToNonTicketedEvents(permObj),
      canEditSubmittedNonTicketedEventsResponseForm:
        canEditSubmittedNonTicketedEventsResponseForm(permObj),
      canEditTicketLifeCycle: canEditTicketLifeCycle(permObj),
      canViewModelExplainer: canViewModelExplainer(permObj),
      canAssignEvents: canAssignEvents(permObj),
      canAccessNonTicketsEvents: canAccessNonTicketsEvents(permObj),
      canAccessTickets: canAccessTickets(permObj),
    };

    initPendo(decoratedUser);
    return decoratedUser;
  } catch (reason) {
    throw reason instanceof Error
      ? reason
      : new Error(
          `Unable to synchronize the current session with the server: ${reason}`
        );
  }
};

export { decorateCurrentUser };
