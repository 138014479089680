import type {
  FetchTicketsPageParams,
  RequestDataProps,
} from "./tickets/queries/useFlexTickets";
import type {
  DateRangeFilter,
  SavedViewFilter,
  SavedViewFilterValue,
} from "@/models";
import { SortOrderDirection } from "@/models";

interface UseQueryReturnOptions<T> {
  sorter?: (a: T, b: T) => number;
}

type prepareTicketParamsProps = RequestDataProps & {
  pageParameters?: FetchTicketsPageParams;
  ticketsLimit?: number;
};

const DEFAULT_SAVED_VIEW_SEARCH_LIMIT_IN_DAYS = 90;

type preparedRequestBody = {
  timezone: string;
  offset: number;
  limit: number;
  search_term?: string;
  order?: string;
  filters: Record<string, SavedViewFilterValue>;
  exclude_filters: Record<string, SavedViewFilterValue>;
  expand?: string[];
  fields?: string[];
};

// Type guard function
const isDateRangeFilter = (
  value: SavedViewFilter["value"]
): value is DateRangeFilter =>
  !!value &&
  typeof value === "object" &&
  "type" in value &&
  (value as DateRangeFilter).type === "date";

const calculateDaysFromToday = (dateFromBE: string) => {
  const targetDate: Date = new Date(dateFromBE);
  const today: Date = new Date();
  const diffTime: number = targetDate.getTime() - today.getTime();
  // Convert the difference from milliseconds to days
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const convertRelativeDateToDays = (dateStr: string): number => {
  switch (dateStr) {
    case "now":
    case "today":
      return 0; // 0 days difference for today
    case "yesterday":
      return -1; // -1 days for yesterday
    case "tomorrow":
      return 1; // +1 days for tomorrow
    default: {
      const match = dateStr.match(/^(-?\d+) days$/);
      if (match && match[1]) {
        return parseInt(match[1], 10); // Convert relative days to number
      }
      // If it's an ISO date string, calculate the days difference
      return calculateDaysFromToday(dateStr);
    }
  }
};

const isDateRangeWithinLimit = (
  dateRange: DateRangeFilter,
  limitDays: number
): boolean => {
  if (!dateRange.after) {
    return false;
  }

  const startDays = convertRelativeDateToDays(dateRange.after); // Convert relative dates to days
  const endDays = dateRange.before
    ? convertRelativeDateToDays(dateRange.before) // Convert or calculate days for the 'before' date
    : 0; // Default to 0 if 'before' date is not provided (assuming 'today')

  if (startDays > 0 && endDays > 0) {
    return false; // If both start and end days are in the future
  }

  return startDays <= limitDays; // Check if the start days are within the limit
};

const getDaysUntilDateOrDefault = (inputDate?: string): number => {
  if (inputDate) {
    // If inputDate is provided, return the days difference using calculateThetime
    return calculateDaysFromToday(inputDate);
  }

  // If inputDate is undefined, return the default value of 90 days
  return DEFAULT_SAVED_VIEW_SEARCH_LIMIT_IN_DAYS;
};

const prepareRequestBody = ({
  searchTerm,
  order,
  filters,
  excludeFilters,
  ticketsLimit,
  pageParameters,
  ticketsFields,
  searchTimeLimitInDays,
}: prepareTicketParamsProps): preparedRequestBody => {
  const body: preparedRequestBody = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: 0,
    limit: ticketsLimit || 200,
    filters: {},
    exclude_filters: {},
  };
  if (searchTerm) {
    body.search_term = searchTerm;
  }

  if (order) {
    const prefix = order.direction === SortOrderDirection.DESC ? "-" : "";
    body.order = `${prefix}${order.field}`;
  }

  if (filters) {
    body.filters = {};

    if (!searchTerm) {
      let hasDateRangeFilter = false;
      filters.forEach((filter) => {
        if (isDateRangeFilter(filter.value)) {
          if (!filter?.value?.after || !filter?.value?.before) return;
          hasDateRangeFilter = true;
          body.filters[`${filter.field}_after`] = filter.value.after;
          body.filters[`${filter.field}_before`] = filter.value.before;
          return;
        }
        if (!filter.value || !filter.field) return;
        body.filters[filter.field] = filter.value;
      });

      // Add a specific filter if filters don't contain any Date filter
      if (!hasDateRangeFilter && searchTimeLimitInDays) {
        body.filters.ticket_creation_after = `${searchTimeLimitInDays} days`;
        body.filters.ticket_creation_before = "now";
      }
    }
  }

  if (excludeFilters) {
    excludeFilters.forEach((excludeFilter) => {
      if (!excludeFilter?.field || !excludeFilter.value) return;
      body.exclude_filters[excludeFilter.field] = excludeFilter.value;
    });
  }

  if (pageParameters) {
    body.offset = pageParameters.offset || body.offset;
    body.limit = pageParameters.limit || body.limit;
  }

  if (ticketsFields) {
    const getExpand = ticketsFields.reduce<string[]>((acc, field) => {
      const parts = field.split(".").slice(0, -1);
      parts.forEach((_, idx) => {
        const expansion = parts.slice(0, idx + 1).join(".");
        if (!acc.includes(expansion)) {
          acc.push(expansion); // Only add if the expansion isn't already in the array
        }
      });
      return acc;
    }, []);

    if (getExpand.length > 0) {
      body.expand = getExpand;
    }

    body.fields = ticketsFields;
  }

  return body;
};

/*
  Guarantees that all retries are executed with an exponential delay, preventing undesi
*/
const retryDelayStrategy = (attempt: number) =>
  Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000);

export type {
  UseQueryReturnOptions,
  prepareTicketParamsProps,
  preparedRequestBody,
};
export {
  retryDelayStrategy,
  prepareRequestBody,
  isDateRangeFilter,
  getDaysUntilDateOrDefault,
  calculateDaysFromToday,
  isDateRangeWithinLimit,
};
