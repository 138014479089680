import type { UseQueryOptions } from "@tanstack/react-query";
import type { TicketField } from "@/hooks/useFlexFields";
import type { RequestDataProps } from "./useFlexTickets";
import type { preparedRequestBody } from "@/api";
import { useQuery } from "@tanstack/react-query";
import { prepareRequestBody } from "@/api";
import { useFlexTicketFields } from "@/hooks/useFlexFields";
import { type FlexTicket, respToFlexTicket } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

export interface UseFlexTicketOpts {
  fields?: TicketField[];
}

const flexTicketIdentKey = "flex_ticket";
const emptyFields: TicketField[] = [];

const flexTicketIdent = (ticketId: number, params: preparedRequestBody) => [
  flexTicketIdentKey,
  ticketId,
  JSON.stringify(params),
];

const fetchTicket = (ticketId: number, params: preparedRequestBody) =>
  api
    .post(`tickets_flex/${ticketId}`, JSON.stringify(params))
    .then(handleApiResponse)
    .then(respToFlexTicket);

const useFlexTicket = (
  ticketId: number,
  args?: UseFlexTicketOpts,
  opts?: UseQueryOptions<FlexTicket | undefined>
) => {
  const requestData: RequestDataProps = {
    ticketsFields: useFlexTicketFields(args?.fields || emptyFields)(),
  };
  const params = prepareRequestBody(requestData);

  return useQuery<FlexTicket | undefined>(
    flexTicketIdent(ticketId, params),
    () => fetchTicket(ticketId, params),
    {
      ...opts,
    }
  );
};

const invalidateUseFlexTicketQuery = (ticketId?: number) =>
  queryClient.invalidateQueries([flexTicketIdentKey, ticketId]);

export { useFlexTicket, invalidateUseFlexTicketQuery };
