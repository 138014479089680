import { useCallback } from "react";
import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";
import { DropdownSelect } from "@/components/Select";
import { SortOrderDirection } from "@/models";
import { usePublicConfig } from "@/api";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { useDamagePreventionAuth } from "@/hooks";
import { FilterDialogSection } from "../FilterDialogSection";
import { FilterDialogCard } from "../FilterDialogCard";
import { getAllowedSortOptions } from "./SortAndFilterTab.utils";
import { FiltersSection } from "./FiltersSection";

const SortAndFilterTab = () => {
  const { data: publicConfig } = usePublicConfig();
  const { currentUser } = useDamagePreventionAuth();
  const { sortOrder, resetOrder, setSortOrder, toggleSortOrderDirection } =
    useTicketViewEditor();

  const onSortOrderChange = useCallback(
    (field) => {
      setSortOrder({
        field,
        direction: SortOrderDirection.DESC,
      });
    },
    [setSortOrder]
  );

  return (
    <div className="mb-16 space-y-8">
      <FilterDialogSection title="SORT" onReset={resetOrder}>
        <FilterDialogCard className="flex items-center space-x-3">
          <div className="flex-grow">
            <DropdownSelect
              options={getAllowedSortOptions(publicConfig, currentUser)}
              value={sortOrder && sortOrder.field}
              className="border rounded"
              onChange={onSortOrderChange}
            />
          </div>
          <button
            type="button"
            onClick={toggleSortOrderDirection}
            className="flex items-center justify-center w-8 h-8 text-xl text-black rounded-full icon-semibold text-opacity-66 bg-blue-50"
          >
            <IonIcon
              className={`transition duration-100 transform-gpu
                    ${
                      sortOrder?.direction === SortOrderDirection.ASC &&
                      "-rotate-180"
                    }`}
              icon={arrowDownOutline}
            />
          </button>
        </FilterDialogCard>
      </FilterDialogSection>
      <FiltersSection />
    </div>
  );
};

export { SortAndFilterTab };
