import { useMemo } from "react";
import { ActionLabel, BodyText } from "@urbint/silica";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { AvailableTicketFilters } from "@/api";
import { getFieldLabel } from "@/models";
import { getKeys } from "@/utils";
import { Spinner } from "@/common/Spinner";
import { useToasts } from "@/components/Toasts";
import { useCheckDateRangeWarning } from "@/hooks";
import { FilterDialogSection } from "../../FilterDialogSection";
import { FilterDialogFieldFilterCard } from "../FilterDialogFieldFilterCard";

const FiltersSection = () => {
  const { data: availableFilters, isInitialLoading } = AvailableTicketFilters();
  const {
    filters: filtersData,
    excludeFilters,
    resetFilters,
    addFilter,
    removeFilter,
    replaceFilter,
  } = useTicketViewEditor();
  const { isInDateRangeWithinWarningLimit, limitDays } =
    useCheckDateRangeWarning();
  const { addToast } = useToasts();

  // TODO: Ticket status workaround
  // Note: There are other areas with the same workaround please search by the TODO
  // Temporary 🔨 to hide ticket "stage" filter
  // Ideally this should have been done on the BE for more details check: https://urbint.atlassian.net/browse/DPAPP-846
  const ignoredFilters = ["status"];

  const filters = filtersData?.filter(
    (item) => !ignoredFilters.includes(item.field)
  );
  // --------------------------------------------------------- //

  const availableFilterOpts = useMemo(() => {
    const usedFilters = new Set(filters.map((x) => x.field) || []);
    if (!availableFilters) {
      return undefined;
    }
    return getKeys(availableFilters)
      .filter((x) => !usedFilters.has(x))
      .sort()
      .map((key) => ({
        label: getFieldLabel(key),
        value: availableFilters[key]!,
        dataTestId: "filter-option",
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableFilters, filters]);

  return (
    <FilterDialogSection
      title="FILTERS"
      addOptions={availableFilterOpts}
      onAdd={addFilter}
      onReset={resetFilters}
      dataTestId="filters-section"
    >
      {isInitialLoading && (
        <div className="flex items-center gap-2 justify-center my-8">
          <Spinner />

          <BodyText className="text-neutral-shade-tertiary">
            Loading filters, please wait...
          </BodyText>
        </div>
      )}
      {(!filters || filters.length === 0) && (
        <div className="flex items-center justify-center h-20 font-semibold text-black bg-gray-100 border-4 border-dashed rounded text-opacity-33 text-md">
          <ActionLabel className="opacity-33">
            No filters currently selected
          </ActionLabel>
        </div>
      )}

      {availableFilters &&
        filters?.map((filter) => (
          <FilterDialogFieldFilterCard
            key={filter.field}
            onChange={(newFilter, newExcludeFilter) => {
              if (newFilter && isInDateRangeWithinWarningLimit([newFilter])) {
                addToast(
                  `Date range selection is limited to the past ${limitDays} days. Please adjust your dates accordingly.`,
                  "warning"
                );
              }

              replaceFilter(
                { field: filter.field },
                newFilter,
                newExcludeFilter
              );
            }}
            filter={filter}
            excludeFilter={excludeFilters.find(
              (excludeFilter) => filter.field === excludeFilter.field
            )}
            definition={availableFilters[filter.field]!}
            formatCasing={filter.field !== "member_codes"}
            onClose={() => removeFilter({ field: filter.field })}
          />
        ))}
    </FilterDialogSection>
  );
};

export { FiltersSection };
