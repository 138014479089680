import { ComponentLabel, Icon } from "@urbint/silica";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@/common/Button";
import { useTicket } from "@/api";
import { ForwardTicket } from "@/components/ForwardTicket";
import { EmailTicket } from "@/components/EmailTicket";
import { CreatePdf } from "@/components/CreatePdf";
import { useDamagePreventionAuth, useResponsive } from "@/hooks";
import { Tooltip } from "@/common/Tooltip";
import {
  EMAIL_TICKET_TOOLTIP_TITLE,
  FORWARD_TICKET_TOOLTIP_TITLE,
  PDF_TICKET_TOOLTIP_TITLE,
} from "./TicketDetailsAction.constants";

const TicketDetailsAction = () => {
  const [openForwardTicketModal, setOpenForwardTicketModal] = useState(false);
  const [openEmailTicketModal, setOpenEmailTicketModal] = useState(false);
  const [openCreatePdfTicket, setOpenCreatePdfTicket] = useState(false);

  const { isSm } = useResponsive();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useTicket(+ticketId, undefined, true);
  const {
    currentUser: { canEmailTickets, canForwardTickets },
  } = useDamagePreventionAuth();

  const handleForwardClick = () =>
    setOpenForwardTicketModal(!openForwardTicketModal);
  const handleEmailClick = () => setOpenEmailTicketModal(!openEmailTicketModal);
  const handleCreatePDFClick = () =>
    setOpenCreatePdfTicket(!openCreatePdfTicket);

  return (
    <div className="flex items-center justify-start gap-1">
      <ComponentLabel className="mr-1 ml-3 lg:ml-0">
        #{ticket?.number}
      </ComponentLabel>
      <Button
        className="px-0"
        onClick={handleForwardClick}
        disabled={!canForwardTickets}
      >
        <Tooltip disabled={!isSm} title={FORWARD_TICKET_TOOLTIP_TITLE}>
          <Icon name="forward" className="text-xl" />
        </Tooltip>
      </Button>
      <Button
        className="px-0"
        onClick={handleEmailClick}
        disabled={!canEmailTickets}
      >
        <Tooltip disabled={!isSm} title={EMAIL_TICKET_TOOLTIP_TITLE}>
          <Icon name="mail" className="text-xl" />
        </Tooltip>
      </Button>
      <Button className="px-0" onClick={() => handleCreatePDFClick()}>
        <Tooltip title={PDF_TICKET_TOOLTIP_TITLE} disabled={!isSm}>
          <Icon name="file_pdf" className="text-xl" />
        </Tooltip>
      </Button>
      {canForwardTickets && ticket && openForwardTicketModal && (
        <ForwardTicket
          ticket={ticket}
          isOpen={openForwardTicketModal}
          onDidDismiss={() => setOpenForwardTicketModal(false)}
        />
      )}
      {canEmailTickets && ticket && openEmailTicketModal && (
        <EmailTicket
          ticket={ticket}
          isOpen={openEmailTicketModal}
          onDidDismiss={() => setOpenEmailTicketModal(false)}
        />
      )}
      {ticket && openCreatePdfTicket && (
        <CreatePdf
          ticket={ticket}
          isOpen={openCreatePdfTicket}
          onDidDismiss={() => setOpenCreatePdfTicket(false)}
        />
      )}
    </div>
  );
};

export { TicketDetailsAction };
